import React from 'react';
import { graphql } from 'gatsby';
import Layout from "../components/layout"
import Seo from "../components/seo"
import {Top, TopImg, Title, InfoBlock, Dot} from "../styles/marineStyles"
import Contact from "../components/contact"
import { processImage } from "../helpers";
import { GatsbyImage } from "gatsby-plugin-image";
import convertHtmlToReact from "@hedgedoc/html-to-react";

const Blog = ({ data }) => {
  const { title, relationships: { field_featured_image }, body } = data.nodeArticle;
  const { relationships: {field_media_image: { gatsbyImage }}} = field_featured_image;

  const options = {
    transform: (node) => {
      if (node.name === 'drupal-media') {
        let uuid = node.attribs['data-entity-uuid'];

        return data.allMediaImage.nodes.map((node, index) => {
          if (uuid && node.drupal_id === uuid) {
            const image = processImage(node);
            return (
              <GatsbyImage
                key={index}
                image={image.gatsbyImageData}
                backgroundColor="transparent"
                alt={image.alt}
                objectFit="cover"
                loading="lazy"
              />
            )
          }

          return undefined
        })
      }
    }
  };

  let article_body_elements = body && body.value ? convertHtmlToReact(body.value, options) : null;

  console.log(gatsbyImage);

  return (
    <Layout>
      <Seo
        title={title}
        image={field_featured_image.url}
        description={body.summary}
      />
      <Top>
          <Title style={{letterSpacing: "3px"}}>{ title }</Title>
          <div><Dot/><Dot/><Dot/></div>
          <TopImg>
            <GatsbyImage
              style={{maxHeight: "450px"}}
              image={ gatsbyImage }/>
          </TopImg>
      </Top>
      <InfoBlock style={{width: "74%", marginLeft: "13%", textAlign: "left", paddingBottom: "30px"}}>
        { article_body_elements }
      </InfoBlock>
      <Contact data={data}/>
    </Layout>
  );
};

export default Blog

export const query = graphql`
  query BlogQuery($slug: String!, $images: [String]) {
    nodeArticle(fields: { slug: { eq: $slug } }) {
      postModified: changed(formatString: "MMMM YYYY")
      changed
      created
      title
      nid: drupal_internal__nid
      body {
        value
        summary
      }
      relationships {
        field_featured_image {
          relationships {
            field_media_image {
              gatsbyImage(
                cropFocus: CENTER
                layout: FULL_WIDTH
                fit: COVER
                formats: AUTO
                width: 1024
              )
            }
          }
        }
      }
  }
  allMediaImage(filter: {drupal_id: {in: $images}}) {
      nodes {
        drupal_id
        relationships {
          field_media_image {
            gatsbyImage(
              cropFocus: CENTER
              layout: FULL_WIDTH
              fit: COVER
              formats: AUTO
              width: 1024
            )
          }
        }
        field_media_image {
          alt
          title
        }
      }
    }
  webformWebform(drupal_internal__id: { eq: "9gk5ucvtcrek9dgqtqq28a8fkvvhkdb2" }) {
    drupal_internal__id
    elements {
        name
        type
        attributes {
          name
          value
        }
    }
  }
}
`
